<template>
 <div>
   <v-col
     cols="12"
     class="align-content-end"
   >
     <v-menu
       offset-y
       right
       :close-on-content-click="false"
       transition="slide-y-transition"
     >
       <template v-slot:activator="{ on, attrs }">
         <v-btn
           v-bind="attrs"
           class="ma-2 custom-btn"
           icon
           color='error'
           v-on="on"
           x-small
         >
           <v-icon
             class="error--text"
           >
             fas fa-history
           </v-icon>
           <v-icon>
             fas fa-caret-down
           </v-icon>
         </v-btn>
       </template>
       <v-simple-table class="border-light">
         <template v-slot:default>
           <thead>
           <tr>
             <th
               class="text-left"
               colspan="2"
             >
               Transactions
             </th>
           </tr>
           </thead>
           <tbody>
           <tr
             v-for="(transaction, index) in data"
             :key="index"
           >
             <td class="text-truncate bordure">
               {{ formatDate(transaction.created_at) }}
             </td>
             <td class="bordure">{{ transaction.message }}</td>
           </tr>
           </tbody>
         </template>
       </v-simple-table>
     </v-menu>
   </v-col>
 </div>
</template>
<script>


import moment from 'moment'

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const formatDate = date => moment(date).format('DD/MM/YYYY H:mm:ss')


    return {
      formatDate,
    }
  },

  methods: {
    initialize() {
      this.refillList = JSON.parse(JSON.stringify(data))
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
